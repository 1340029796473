import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { useStaticQuery, graphql, Link } from 'gatsby';
import styled from '@emotion/styled';
import BackgroundImage from 'gatsby-background-image/index';
import { Box, Button, Divider, Flex, Stack, Text } from '@chakra-ui/core';
import Img from 'gatsby-image/index';
import SectionFull from '../components/sectionFull';

const StyledBanner = styled(BackgroundImage)(
    ({ theme }) => `
        &::before,
        &::after {
            filter: brightness(0.6);
        }
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        border-bottom: 8px solid ${theme.color.brand};
        margin-bottom: 80px;
        height: 300px;
        text-align: center;
        width: 100%;
    `
);

const ServicesPage = () => {
    const data = useStaticQuery(graphql`
        query {
            servicesBanner: file(relativePath: { eq: "services-banner.jpg" }) {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 1920) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            services: file(relativePath: { eq: "services-ladder-assist-102782513.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 640) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            services2: file(relativePath: { eq: "services-drone-unsplash.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 640) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            services3: file(relativePath: { eq: "services-roof-tarping-18048332.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 640) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            services4: file(relativePath: { eq: "services-disaster-144246665.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 640) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `);
    return (
        <Layout>
            <SEO title="Services" />
            <StyledBanner Tag="section" fluid={data.servicesBanner.childImageSharp.fluid}>
                <Box>
                    <Text fontSize="5xl" color="white" fontWeight="semibold" pt="120px">
                        Services
                    </Text>
                </Box>
            </StyledBanner>
            <Flex
                justifyContent="flex-start"
                direction={['column', 'row']}
                mr={['20px', '80px']}
                ml={['20px', '80px']}
                mb={['20px', '40px']}>
                <Box w={['100%', '40%']} mr={['20px', '40px']}>
                    <Img fluid={data.services.childImageSharp.fluid} />
                </Box>
                <Box mb={['20px', '60px']} w={['100%', '50%']}>
                    <Stack spacing={0} mb="20px">
                        <a id="ladder"></a>
                        <Text fontSize="4xl" mt={['20px', '0']}>
                            Ladder Assist
                        </Text>
                    </Stack>
                    <Box>
                        <Text mb="20px">
                            Vertical Link Services works with the needs of all insurance companies;
                            independent claims adjusters and roofing contractors by providing ladder
                            assists services for all residential and commercial properties.
                        </Text>
                        <Text>
                            We are able to provide access to a single or multi story roof for the
                            engineer who isn’t a ladder assist provider. We are professional,
                            courteous, and knowledgeable with all the required documentation for the
                            adjuster, during a ladder assist claim survey.
                        </Text>
                    </Box>
                </Box>
            </Flex>
            <Divider borderColor="gray.300" mb="40px" />
            <Flex
                justifyContent="flex-start"
                direction={['column', 'row']}
                mr={['20px', '80px']}
                ml={['20px', '80px']}
                mb={['20px', '40px']}>
                <Box w={['100%', '40%']} mr={['20px', '40px']}>
                    <Img fluid={data.services2.childImageSharp.fluid} />
                </Box>
                <Box mb={['20px', '60px']} w={['100%', '50%']}>
                    <Stack spacing={0} mb="20px">
                        <a id="drone"></a>
                        <Text fontSize="4xl" mt={['20px', '0']}>
                            Drone Surveys
                        </Text>
                    </Stack>
                    <Box>
                        <Text mb="20px">
                            Where no man can go when danger is lurking, you will find us.
                        </Text>
                        <Text mb="20px">
                            Our Drone survey service is tailored to situations where we cannot get
                            on a roof to inspect because of a roof collapse or severe water or fire
                            damage. Why risk the lives of roof inspectors, and cause further untold
                            damage to the roof when you can get a drone survey provider that allows
                            you to access the damage without loss of live, injury or money?
                        </Text>
                        <Text>
                            Our FAA certified license drone pilots are capable of a complete survey
                            of dangerous or extreme difficult access to structures for damage
                            assessments.
                        </Text>
                    </Box>
                </Box>
            </Flex>
            <Divider borderColor="gray.300" mb="40px" />
            <Flex
                justifyContent="flex-start"
                direction={['column', 'row']}
                mr={['20px', '80px']}
                ml={['20px', '80px']}
                mb={['20px', '40px']}>
                <Box w={['100%', '40%']} mr={['20px', '40px']}>
                    <Img fluid={data.services3.childImageSharp.fluid} />
                </Box>
                <Box mb={['20px', '60px']} w={['100%', '50%']}>
                    <Stack spacing={0} mb="20px">
                        <a id="roof"></a>
                        <Text fontSize="4xl" mt={['20px', '0']}>
                            Roof Tarping
                        </Text>
                    </Stack>
                    <Box>
                        <Text mb="20px">
                            When the elements are at their worst, we are ready to pull up our Boots
                            and get to work.
                        </Text>
                        <Text mb="20px">
                            Our Roof tarping service is tailored to “claims customers” that have a
                            leaking roof situation. When an insurance inspection claim is sent to
                            us, you can also request for our roof tarping services as well.
                        </Text>
                        <Text>
                            At Vertical Link Services, Dawsonville, Georgia, our licensed
                            contractors are courteous, professional, and have extensive experience
                            working with insurance companies, claims adjusters and roofing
                            contractors.
                        </Text>
                    </Box>
                </Box>
            </Flex>
            <Divider borderColor="gray.300" mb="40px" />
            <Flex
                justifyContent="flex-start"
                direction={['column', 'row']}
                mr={['20px', '80px']}
                ml={['20px', '80px']}
                mb={['20px', '40px']}>
                <Box w={['100%', '40%']} mr={['20px', '40px']}>
                    <Img fluid={data.services4.childImageSharp.fluid} />
                </Box>
                <Box mb={['20px', '60px']} w={['100%', '50%']}>
                    <Stack spacing={0} mb="20px">
                        <a id="disaster"></a>
                        <Text fontSize="4xl" mt={['20px', '0']}>
                            Disaster Response
                        </Text>
                    </Stack>
                    <Box>
                        <Text mb="20px">
                            When a man-made or natural disaster strikes, you need a team of
                            professionals with the required experience, calm, and resources to help
                            weather the storm, and restore faith and help others. It’s crucial that
                            affected person have dry shelter and protection from the elements.
                        </Text>
                        <Text>
                            At Vertical Link services, our team of trained technical specialists
                            uses pre-packed sets of standardized equipment in immediate disaster
                            response for emergency roof tarping and post storm surveying. Our teams
                            are prepared and ready for deployment to any location within the
                            continental US.
                        </Text>
                    </Box>
                </Box>
            </Flex>
            <SectionFull>
                <Flex
                    display={['none', 'flex']}
                    justifyContent="flex-start"
                    alignItems="center"
                    bg="gray.50"
                    h="200px">
                    <Box ml="80px" mr="25px">
                        <Text fontSize="3xl">To schedule a service request call</Text>
                    </Box>
                    <Text fontSize="4xl" fontWeight="bold" mr="25px">
                        800.909.2711
                    </Text>
                    <Text fontSize="3xl">or</Text>
                    <Button
                        variant="ghost"
                        borderRadius="0"
                        border="2px"
                        ml="25px"
                        size="lg"
                        _hover={{ bg: '#000', color: 'white' }}>
                        <Link to="/claim">SCHEDULE A CLAIM</Link>
                    </Button>
                </Flex>
            </SectionFull>
        </Layout>
    );
};

export default ServicesPage;
